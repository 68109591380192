
import { NeuralNet } from "@/model/neuralnet";
import { OrganismInputNames, OrganismOutputNames } from "@/model/organism";
import cytoscape from "cytoscape";
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    brain: { type: Object },
  },
  mounted() {
    this.updateGraph();
    window.requestAnimationFrame(this.animate);
  },
  updated() {
    this.updateGraph();
  },
})
export default class BrainView extends Vue {
  brain!: NeuralNet;
  cy!: cytoscape.Core;

  get elements() {
    return () => {
      const nodes: Array<cytoscape.ElementDefinition> = [];
      for (let i = 0; i < this.brain.numNeurons; i++) {
        nodes.push({
          data: {
            id: `n${i}`,
            name: this.getNodeName(i),
            color: "hsl(230, 84%, 50%)",
          },
        });
      }

      const edges: Array<cytoscape.ElementDefinition> = [];
      for (let i = 0; i < this.brain.numNeurons; i++) {
        for (let j = 0; j < this.brain.numNeurons; j++) {
          if (this.brain.getWeight(i, j) != 0.0) {
            edges.push({
              data: {
                source: `n${i}`,
                target: `n${j}`,
                width: Math.abs(this.brain.getWeight(i, j)),
                lineColor: this.brain.getWeight(i, j) < 0.0 ? "red" : "green",
              },
            });
          }
        }
      }
      return {
        nodes,
        edges,
      } as cytoscape.ElementsDefinition;
    };
  }

  getNodeName(index: number) {
    if (this.brain.isInput(index)) {
      return OrganismInputNames[index];
    }
    if (this.brain.isHidden(index)) {
      const hiddenIndex = index - this.brain.config.numInputs;
      return `Neuron ${hiddenIndex}`;
    }
    const outputIndex =
      index - this.brain.config.numInputs - this.brain.config.numHidden;
    return OrganismOutputNames[outputIndex];
  }

  updateGraph() {
    if (this.cy) {
      this.cy.unmount();
    }
    this.cy = cytoscape({
      container: this.$refs.cy as HTMLElement,
      elements: this.elements(),
      style: [
        {
          selector: "node",
          style: {
            "background-color": "data(color)",
            label: "data(name)",
          },
        },
        {
          selector: "edge",
          style: {
            width: "data(width)",
            "line-color": "data(lineColor)",
            "target-arrow-color": "data(lineColor)",
            "target-arrow-shape": "triangle",
            "curve-style": "bezier",
          },
        },
      ],
      layout: {
        //name: "grid",
        //rows: 4,
        //name: "cose",
        //name: "concentric",
        name: "circle",
      },
      styleEnabled: true,
      autounselectify: false,
      boxSelectionEnabled: false,
    });
    this.cy.resize();
    this.cy.fit();
    this.cy.zoomingEnabled(false);
    this.cy.panningEnabled(false);
  }

  animate() {
    const outValue = (i: number) => {
      const neuronState = this.brain.neurons[i];
      const lValue = 50 - 40 * neuronState;
      return Math.round(Math.max(10, Math.min(90, lValue))).toFixed(0);
    };
    const inValue = (i: number) => {
      const neuronState = this.brain.neurons[i];
      const lValue = 90 - 80 * neuronState;
      return Math.round(Math.max(10, Math.min(90, lValue))).toFixed(0);
    };

    for (let i = 0; i < this.brain.numNeurons; i++) {
      if (this.brain.isInput(i)) {
        this.cy
          .getElementById(`n${i}`)
          .data("color", `hsl(210, 84%, ${inValue(i)}%)`);
      } else if (this.brain.isHidden(i)) {
        this.cy
          .getElementById(`n${i}`)
          .data("color", `hsl(170, 84%, ${outValue(i)}%)`);
      } else {
        this.cy
          .getElementById(`n${i}`)
          .data("color", `hsl(30, 84%, ${outValue(i)}%)`);
      }
    }

    window.requestAnimationFrame(this.animate);
  }
}
