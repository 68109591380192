import { InjectionKey } from "vue";
import { createStore, Store, useStore as baseUseStore } from "vuex";
import { SimulationModule, SimulationState } from "./simulationModule";

// define your typings for the store state
export interface RootState {}

/**
 * IStoreState - Store Interface for vuex.d.ts
 */
export interface IStoreState extends RootState {
  simulation: SimulationState;
}

/**
 * key - Define the injection key for the store
 */
export const key: InjectionKey<Store<RootState>> = Symbol();

/**
 * store - Define the store
 */
export const store = createStore<RootState>({
  state: {},
  modules: {
    simulation: SimulationModule,
  },
});

/**
 * useStore() - Return the store
 * @returns The store
 */
export function useStore() {
  return baseUseStore(key);
}
