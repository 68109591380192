
import { NeuralNet } from "@/model/neuralnet";
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    brain: { type: Object },
  },
})
export default class BrainStats extends Vue {
  brain!: NeuralNet;

  get _brainStats() {
    const brainConfig = this.brain.config;
    const brainOpt = this.brain.optimizedNet!.config;
    const fullSize =
      brainConfig.numInputs + brainConfig.numHidden + brainConfig.numOutputs;
    const optSize =
      brainOpt.numInputs + brainOpt.numHidden + brainOpt.numOutputs;
    const totalMul = fullSize * fullSize;
    const optMul = optSize * optSize;
    const sizeReduction = 100.0 * (1.0 - optSize / fullSize);
    const speedUp = 100.0 * (1.0 - optMul / totalMul);

    return {
      numInputs: brainConfig.numInputs,
      numHidden: brainConfig.numHidden,
      numOutputs: brainConfig.numOutputs,
      optInputs: brainOpt.numInputs,
      optHidden: brainOpt.numHidden,
      optOutputs: brainOpt.numOutputs,
      totalNeurons: fullSize,
      optimizedSize: optSize,
      sizeReduction: `-${sizeReduction.toFixed(0)}%`,
      speedUp: `+${speedUp.toFixed(0)}%`,
    };
  }
}
