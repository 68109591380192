import { Organism } from "./organism";

/**
 * OrganismGenome - Describe the genome of an organism
 */
export type OrganismGenome = Array<number>;

/**
 * RandomGenome() - Function to produce a random genome sequence of specified length
 * @param genomeSize
 * @returns
 */
export const RandomGenome = (genomeSize: number): OrganismGenome => {
  const uint32Max = 4294967295;
  const genome = new Array<number>(genomeSize);
  for (let i = 0; i < genome.length; i++) {
    genome[i] = Math.floor(Math.random() * uint32Max);
  }
  return genome;
};

/**
 * ChildGenome() - Function to produce a child genome sequence from two random parents
 * @param parents
 * @returns
 */
export const ChildGenome = (parents: Array<Organism>): OrganismGenome => {
  const imother = Math.floor(Math.random() * parents.length);
  const ifather = Math.floor(Math.random() * parents.length);
  const mother = parents[imother];
  const father = parents[ifather];
  const childGenome = new Array<number>(mother.config.genome.length);
  for (let i = 0; i < mother.config.genome.length; i++) {
    if (i % 2 == 0) {
      childGenome[i] = mother.config.genome[i];
    } else {
      childGenome[i] = father.config.genome[i];
    }
  }
  return childGenome;
};
