import { ChildGenome, OrganismGenome, RandomGenome } from "./genome";
import { NeuralNet } from "./neuralnet";
import { Simulation } from "./simulation";
import { Location, World } from "./world";

/**
 * OrganismOptions - Options for construction of an organism
 */
export interface OrganismOptions {
  // location of the organism
  location: Location;

  // number of hidden neurons in the brain
  brainSize: number;

  // genome = each number is converted into a brain connection
  genome: OrganismGenome;
}

// inputs
const Osc = 0; // [0..1] oscillating input
const Age = 1; // [0..1] just born ... end of life
const Rnd = 2; // [0..1] random input
const PosX = 3; // [0..1] x-position in world
const PosY = 4; // [0..1] y-position in world

// outputs
const MvX = 0; // [-1..1] left..right
const MvY = 1; // [-1..1] backward...forward

export const OrganismInputNames: Record<number, string> = {
  0: "Osc",
  1: "Age",
  2: "Rnd",
  3: "PosX",
  4: "PosY",
};

export const OrganismOutputNames: Record<number, string> = {
  0: "MvX",
  1: "MvY",
};

/**
 * Organism - A single organism
 */
export class Organism {
  config: Readonly<OrganismOptions>;
  location: Location;
  brain: NeuralNet;
  mx: number = 0;
  my: number = 0;

  constructor(options: OrganismOptions) {
    // store the options
    this.config = options;
    // create the brain
    this.brain = new NeuralNet({
      numInputs: 5,
      numHidden: options.brainSize,
      numOutputs: 2,
    });
    // turn the genome into brain connections
    this.wireUpBrain();
    this.brain.optimize();
    // place the organism
    this.location = { x: options.location.x, y: options.location.y };
  }

  perceive(simulation: Simulation) {
    this.brain.neurons[Osc] = 0.5 * (Math.sin(simulation.time) + 1.0);
    this.brain.neurons[Age] += 1.0 / 180;
    this.brain.neurons[Rnd] = Math.random();
    this.brain.neurons[PosX] = this.location.x / simulation.config.gridSize;
    this.brain.neurons[PosY] = this.location.y / simulation.config.gridSize;
  }

  act(simulation: Simulation) {
    this.doMove(simulation);
  }

  wireUpBrain() {
    for (let igenome = 0; igenome < this.config.genome.length; igenome++) {
      const gen = this.config.genome[igenome];
      this.createBrainConnection(gen);
    }
  }

  createBrainConnection(gen: number) {
    const n1 = (gen >> 31) & 0x1;
    const x = (gen >> 24) & 0x7f;
    const n2 = (gen >> 23) & 0x01;
    const y = (gen >> 16) & 0x7f;
    const z = gen & 0x0000ffff;
    const numInputs = this.brain.config.numInputs;
    const numHidden = this.brain.config.numHidden;
    const numOutputs = this.brain.config.numOutputs;

    let from = 0,
      to = 0;

    if (n1 == 0) {
      from = x % numInputs;
    } else {
      from = numInputs + (x % numHidden);
    }

    if (n2 == 0) {
      to = numInputs + (y % numHidden);
    } else {
      to = numInputs + numHidden + (y % numOutputs);
    }

    const wsign = (z & 0x8000) >> 15;
    const wvalue = (wsign ? -4.0 : 4.0) * ((z & 0x7fff) / 32767);
    this.brain.setWeight(from, to, wvalue);
  }

  doMove(simulation: Simulation) {
    const outputs = this.brain.getOutputs();

    this.mx += outputs[MvX];
    this.my += outputs[MvY];

    if (this.mx >= 1) {
      this.mx -= 1;
      this.move(simulation.world, 1, 0);
    } else if (this.mx <= -1) {
      this.mx += 1;
      this.move(simulation.world, -1, 0);
    }

    if (this.my >= 1) {
      this.move(simulation.world, 0, 1);
      this.my -= 1;
    } else if (this.my <= -1) {
      this.my += 1;
      this.move(simulation.world, 0, -1);
    }
  }

  move(world: World, dirx: number, diry: number): boolean {
    let targetx = this.location.x + dirx;
    let targety = this.location.y + diry;
    const gridSize = world.config.gridSize;

    // test world boundaries
    if (targetx < 0) {
      targetx += gridSize;
    } else if (targetx >= gridSize) {
      targetx -= gridSize;
    }
    if (targety < 0) {
      targety += gridSize;
    } else if (targety >= gridSize) {
      targety -= gridSize;
    }

    //if (targetx < 0 || targetx >= gridSize) {
    //  return false;
    //}
    //if (targety < 0 || targety >= gridSize) {
    //  return false;
    //}

    // test if target field is occupied
    const targetOrganism = world.getCell(targetx, targety);
    if (targetOrganism != -1) {
      return false;
    }

    // move organism to target field
    const thisOrganism = world.getCell(this.location.x, this.location.y);
    world.setCell(this.location.x, this.location.y, -1);
    world.setCell(targetx, targety, thisOrganism);
    return true;
  }
}

/**
 * OrganismSpawnFunction - An arrow function to produce a new organism from a set of parent organisms.
 */
export type OrganismFactoryFunc = (
  location: Location,
  brainSize: number,
  genomeSize: number,
  parents?: Array<Organism>
) => Organism;

/**
 * DefaultSpawnFunction - Default spawn function for organisms
 * @param genomeSize
 * @param location
 * @param parents
 * @returns
 */
export const DefaultSpawnFunction: OrganismFactoryFunc = (
  location: Location,
  brainSize: number,
  genomeSize: number,
  parents?: Array<Organism>
): Organism => {
  if (parents) {
    const genome = ChildGenome(parents);
    return new Organism({
      brainSize: brainSize,
      genome,
      location,
    });
  }

  // pick random genome
  const genome = RandomGenome(genomeSize);
  return new Organism({
    brainSize: brainSize,
    genome,
    location,
  });
};
