
import { SimulationOptions } from "@/model/simulation";
import { SimulationModule } from "@/store/simulationModule";
import { Options, Vue } from "vue-class-component";
import { getModule } from "vuex-module-decorators";

@Options({
  props: {
    msg: String,
  },
  beforeMount() {
    this.simMod = getModule(SimulationModule, this.$store);
  },
  mounted() {
    this.updateOptions();
  },
  data() {
    return {
      lastFrame: null,
      simulationOptions: {
        gridSize: 128,
        numOrganisms: 500,
        brainSize: 2,
        genomeSize: 10,
        maxAge: 180,
        maxGenerations: 200,
      } as SimulationOptions,
    };
  },
})
export default class SimulationControls extends Vue {
  msg!: string;
  simMod!: SimulationModule;
  simulationOptions!: SimulationOptions;
  lastFrame!: number | null;

  get simulation() {
    return this.simMod.simulation!;
  }

  get _generationsDone() {
    return this.simulation.generationsDone;
  }

  get _maxGenerations() {
    return this.simulation.maxGenerations;
  }

  get _stepsDone() {
    return this.simulation.stepsDone;
  }

  get _maxSteps() {
    return this.simulation.maxSteps;
  }

  get _frameRate() {
    return this.simMod.frameRate;
  }

  get _isRunning() {
    return this.simulation.running;
  }

  resetSimulation() {
    this.simMod.stop();
    this.simMod.resetSimulation();
  }

  resetGeneration() {
    this.simMod.stop();
    this.simMod.resetGeneration();
  }

  repopulate() {
    this.simMod.repopulate(this.simulationOptions);
  }

  start() {
    if (!this.simulation.running) {
      this.simMod.start();
      window.requestAnimationFrame(this.animate);
    }
  }

  stop() {
    if (this.simulation.running) {
      this.simMod.stop();
    }
  }

  step() {
    if (!this.simulation.running) {
      this.simMod.step(1.0 / 33);
    }
  }

  animate(time: number) {
    const deltaTimeRaw =
      time - (this.lastFrame == null ? time : this.lastFrame);
    const deltaTime = deltaTimeRaw / 1000.0;

    if (this.simulation.running) {
      this.simMod.step(deltaTime);
      window.requestAnimationFrame(this.animate);
    }

    this.lastFrame = time;
  }

  updateOptions() {
    const currentOpts = this.simMod.simulation!.config;
    this.simulationOptions.gridSize = currentOpts.gridSize;
    this.simulationOptions.numOrganisms = currentOpts.numOrganisms;
    this.simulationOptions.brainSize = currentOpts.brainSize;
    this.simulationOptions.genomeSize = currentOpts.genomeSize;
    this.simulationOptions.maxAge = currentOpts.maxAge;
    this.simulationOptions.maxGenerations = currentOpts.maxGenerations;
  }
}
