
import { SimulationModule } from "@/store/simulationModule";
import { Options, Vue } from "vue-class-component";
import { getModule } from "vuex-module-decorators";

@Options({
  props: {},
  beforeMount() {
    this.simMod = getModule(SimulationModule, this.$store);
  },
})
export default class OrganismSelect extends Vue {
  simMod!: SimulationModule;

  unselect() {
    this.simMod.selectOrganism();
  }
  next() {
    const numOrganisms = this.simMod.simulation!.organisms.length;
    const nextOrganism = (this.simMod.focusOrganism + 1) % numOrganisms;
    this.simMod.selectOrganism(this.simMod.simulation!.organisms[nextOrganism]);
  }

  prev() {
    const numOrganisms = this.simMod.simulation!.organisms.length;
    const nextOrganism =
      (this.simMod.focusOrganism + numOrganisms - 1) % numOrganisms;
    this.simMod.selectOrganism(this.simMod.simulation!.organisms[nextOrganism]);
  }

  first() {
    this.simMod.selectOrganism(this.simMod.simulation!.organisms[0]);
  }

  last() {
    const numOrganisms = this.simMod.simulation!.organisms.length;
    this.simMod.selectOrganism(
      this.simMod.simulation!.organisms[numOrganisms - 1]
    );
  }
}
