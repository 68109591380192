import { DefaultSpawnFunction, Organism } from "./organism";
import { World } from "./world";

export interface SimulationOptions {
  gridSize: number;
  numOrganisms: number;
  brainSize: number;
  genomeSize: number;
  maxAge: number;
  maxGenerations: number;
}

export class Simulation {
  config: Readonly<SimulationOptions>;
  world: World;

  t: number;
  deltaT: number;
  sim: number = 0;

  running: boolean = false;
  maxSteps: number = 180;
  maxGenerations: number = 800;
  stepsDone: number = 0;
  generationsDone: number = 0;

  constructor(options: SimulationOptions) {
    this.config = options;
    this.maxSteps = options.maxAge;
    this.maxGenerations = options.maxGenerations;
    this.world = new World({
      gridSize: options.gridSize,
    });

    this.t = 0;
    this.deltaT = 1.0 / 25;
  }

  getCell(x: number, y: number) {
    return this.world.getCell(x, y);
  }

  setCell(x: number, y: number, organism: number) {
    this.world.setCell(x, y, organism);
  }

  get organisms() {
    return this.world.organisms;
  }

  /**
   * populate the world with organisms
   */
  populate(parents?: Array<Organism>) {
    this.world.populate(
      this.config.numOrganisms,
      this.config.brainSize,
      this.config.genomeSize,
      DefaultSpawnFunction,
      parents
    );
  }

  /**
   * single step "perceive" phase
   */
  perceive() {
    for (let i = 0; i < this.organisms.length; i++) {
      // let the organism "see" the world
      this.organisms[i].perceive(this);
    }
  }

  /**
   * single step "processing" phase
   */
  process() {
    for (let i = 0; i < this.organisms.length; i++) {
      // let the organism "process" what it saw
      this.organisms[i].brain.update();
    }
  }

  /**
   * single step "act" phase
   */
  act() {
    for (let i = 0; i < this.organisms.length; i++) {
      // let the organism "act" in the world
      this.organisms[i].act(this);
    }
  }

  /**
   * simulate a single step
   */
  step(deltaT: number) {
    this.t += deltaT;
    this.perceive();
    this.process();
    this.act();
  }

  /**
   * return current time
   */
  get time() {
    return this.t;
  }
}
