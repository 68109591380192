import { Organism } from "@/model/organism";
import { GenerationRecord } from "@/model/recording";
import { Simulation, SimulationOptions } from "@/model/simulation";
import { thisTypeAnnotation } from "@babel/types";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";

export interface SimulationState {
  simulation: Simulation | null;
}

@Module({
  name: "simulation",
})
export class SimulationModule extends VuexModule implements SimulationState {
  simulation: Simulation | null = null;
  firstGen: GenerationRecord | null = null;
  records: Array<GenerationRecord> = [];
  nextRecord: GenerationRecord | null = null;
  focusOrganism: number = -1;
  frameRate: number = 0.0;

  @Mutation
  NEW_SIMULATION(options: SimulationOptions) {
    // create new simulation
    this.simulation = new Simulation(options);

    // with new random creatures
    this.simulation!.populate();

    // drop records
    this.records.splice(0, this.records.length);

    // create first record
    this.nextRecord = this.firstGen = {
      generation: 1,
      initialPopulation: this.simulation!.organisms.map((o) => o.config),
      survivors: 0,
    };
  }

  @Mutation
  POPULATE(parents?: Array<Organism>) {
    this.simulation!.populate(parents);
  }

  @Mutation
  SIMULATION_STEP(deltaTime: number) {
    this.simulation!.stepsDone++;
    if (this.simulation!.stepsDone < this.simulation!.maxSteps) {
      // single step
      this.simulation!.step(deltaTime);
    }

    // update frame rate
    this.frameRate = 1.0 / (deltaTime == 0 ? 0.0000000001 : deltaTime);
  }

  @Mutation
  END_OF_GENERATION() {
    if (this.simulation!.stepsDone == this.simulation!.maxSteps) {
      // get survivors
      const survivors = this.simulation!.organisms.filter((p) => {
        const matchedRegions = this.simulation!.world.survivalRegions.filter(
          (r) => r.contains(p.location.x, p.location.y)
        );
        return matchedRegions.length > 0;
      });

      // record end of generation
      this.nextRecord!.survivors = survivors.length;
      this.records.push(this.nextRecord!);

      // advance to next generation
      this.simulation!.generationsDone++;
      this.simulation!.stepsDone = 0;
      this.simulation!.populate(survivors);

      // prepare next generation record
      this.nextRecord = {
        generation: this.simulation!.generationsDone + 1,
        initialPopulation: this.simulation!.organisms.map((o) => o.config),
        survivors: 0,
      };
    }
  }

  @Mutation
  END_OF_SIMULATION() {
    // end of simulation
    if (this.simulation!.generationsDone == this.simulation!.maxGenerations) {
      this.simulation!.running = false;
    }
  }

  @Mutation
  SET_RUNNING(value: boolean) {
    this.simulation!.running = value;
  }

  @Mutation
  RESET_SIMULATION() {
    this.simulation!.stepsDone = 0;
    this.simulation!.generationsDone = 0;
    this.simulation!.world.populateWith(this.firstGen!.initialPopulation);
  }

  @Mutation
  RESET_GENERATION() {
    this.simulation!.stepsDone = 0;
    this.simulation!.world.reset();
  }

  @Mutation
  SET_FOCUS_ORGANISM(payload: number) {
    this.focusOrganism = payload % this.simulation!.organisms.length;
  }

  get _focusOrganism() {
    return this.simulation!.organisms[this.focusOrganism];
  }

  @Action
  newSimulation(options: SimulationOptions) {
    this.NEW_SIMULATION(options);
  }

  @Action
  step(deltaTime: number) {
    this.SIMULATION_STEP(deltaTime);
    this.END_OF_GENERATION();
    this.END_OF_SIMULATION();
  }

  @Action
  resetGeneration() {
    this.SET_RUNNING(false);
    this.RESET_GENERATION();
  }

  @Action
  resetSimulation() {
    this.SET_RUNNING(false);
    this.RESET_SIMULATION();
  }

  @Action
  repopulate(simulationOptions?: SimulationOptions) {
    this.SET_RUNNING(false);
    const config = simulationOptions || this.simulation!.config;
    this.NEW_SIMULATION(config);
  }

  @Action
  start() {
    this.SET_RUNNING(true);
  }

  @Action
  stop() {
    this.SET_RUNNING(false);
  }

  @Action
  toggleRunning() {
    this.SET_RUNNING(!this.simulation!.running);
  }

  @Action
  selectOrganism(payload?: Organism) {
    if (this.simulation === null) return;
    if (payload) {
      const focusOrganism = this.simulation.organisms.findIndex(
        (o) => o == payload
      );
      this.SET_FOCUS_ORGANISM(focusOrganism);
    } else {
      this.SET_FOCUS_ORGANISM(-1);
    }
  }
}
