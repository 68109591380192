
import { Options, Vue } from "vue-class-component";
import WorldView from "@/components/WorldView.vue";
import BrainView from "@/components/BrainView.vue";
import GenomeView from "@/components/GenomeView.vue";
import BrainStats from "@/components/BrainStats.vue";
import OrganismSelect from "@/components/OrganismSelect.vue";
import SimulationControls from "@/components/SimulationControls.vue";
import { SimulationModule } from "@/store/simulationModule";
import { getModule } from "vuex-module-decorators";
import { Organism } from "@/model/organism";
import { Simulation } from "@/model/simulation";

@Options({
  components: {
    WorldView,
    BrainView,
    SimulationControls,
    OrganismSelect,
    BrainStats,
    GenomeView,
  },
  beforeMount() {
    this.simulation = getModule(SimulationModule, this.$store);
  },
  data() {
    return {
      lastFrame: null,
      enableWorldView: true,
    };
  },
})
export default class Home extends Vue {
  simulation!: SimulationModule;
  lastFrame!: number | null;
  enableWorldView!: boolean;

  selectOrganism(s: Simulation, organism: Organism) {
    this.simulation.selectOrganism(organism);
  }

  toggleWorldView() {
    this.enableWorldView = !this.enableWorldView;
  }
}
