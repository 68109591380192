import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { store, key } from "./store";
import "@/styles/mystyles.scss";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { SimulationModule } from "./store/simulationModule";
import { getModule } from "vuex-module-decorators";

library.add(fas);

createApp(App)
  .component("fa-icon", FontAwesomeIcon)
  .use(router)
  .use(store, key)
  .mount("#app");

const simMod = getModule(SimulationModule, store);
simMod.newSimulation({
  gridSize: 128,
  numOrganisms: 500,
  brainSize: 2,
  genomeSize: 10,
  maxAge: 180,
  maxGenerations: 200,
});
