import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "genomeView" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx._genome, (gen, index) => {
      return (_openBlock(), _createElementBlock("span", {
        class: "tag is-dark ml-1",
        key: index
      }, "0x" + _toDisplayString(gen.toString(16).toUpperCase()), 1))
    }), 128))
  ]))
}