
import { Organism } from "@/model/organism";
import { Simulation } from "@/model/simulation";
import { SimulationModule } from "@/store/simulationModule";
import { Options, Vue } from "vue-class-component";
import { getModule } from "vuex-module-decorators";

@Options({
  props: {
    simulation: { type: Object },
  },
  beforeMount() {
    this.simModule = getModule(SimulationModule, this.$store);
  },
  emits: ["selectOrganism"],
})
export default class WorldView extends Vue {
  simulation!: Simulation;
  simModule!: SimulationModule;

  get _toWorld() {
    return (c: number) => {
      const scale = 8;
      const offset = 4;
      return offset + scale * c;
    };
  }

  get _toWorldScale() {
    return (c: number) => {
      const scale = 8;
      return scale * c;
    };
  }

  get _viewBox() {
    const vbSize = this.simulation.config.gridSize * 8;
    return `0 0 ${vbSize} ${vbSize}`;
  }

  get _organisms() {
    return () => this.simulation.organisms;
  }

  get _survivalRegions() {
    return this.simulation.world.survivalRegions;
  }

  get _colorByGenome() {
    return (organism: Organism) => {
      const genomeStr = organism.config.genome
        .map((v) => v.toString(10))
        .reduce((a, b) => b + a, "");
      const genomeInt = BigInt(genomeStr);
      const colorValue = genomeInt % BigInt(16777215);
      return `#${colorValue.toString(16)}`;
    };
  }

  get _focusOrganism() {
    return this.simModule._focusOrganism;
  }

  onSelectOrganism(organism: Organism) {
    this.$emit("selectOrganism", this.simulation, organism);
  }
}
