/**
 * NeuralNetOptions - Describe the options for a neural net
 */
export interface NeuralNetOptions {
  numInputs: number;
  numHidden: number;
  numOutputs: number;
}

/**
 * NeuralNet - Describe an instance of a neural net
 */
export class NeuralNet {
  neurons: Array<number>; // list of nodes
  config: Readonly<NeuralNetOptions>; // neural net options
  weights: Array<number>; // list of node weights

  isOptimized: boolean = false;
  optimizedNet: NeuralNet | null = null;
  neuronMap: Array<number> = []; // Index der Ursprungsneuronen

  constructor(options: NeuralNetOptions) {
    this.config = options;
    this.neurons = new Array<number>(this.numNeurons).fill(0);
    this.weights = new Array<number>(this.numNeurons * this.numNeurons).fill(0);
  }

  get numNeurons() {
    return (
      this.config.numInputs + this.config.numHidden + this.config.numOutputs
    );
  }

  getWeight(fromNode: number, toNode: number) {
    return this.weights[fromNode * this.numNeurons + toNode];
  }

  setWeight(fromNode: number, toNode: number, value: number) {
    this.weights[fromNode * this.numNeurons + toNode] = value;
  }

  /**
   * getOutput() - Calculate the output for a single neuron
   * @param node
   * @returns
   */
  getNeuronOutput(node: number) {
    const innerSum = this.neurons
      .map((inValue, i) => inValue * this.getWeight(i, node))
      .reduce((a, b) => a + b, 0);
    return Math.tanh(innerSum);
  }

  /**
   * update() - Process input values through all neurons
   */
  update() {
    // non optimized net
    if (!this.isOptimized) {
      for (let i = this.config.numInputs; i < this.numNeurons; i++) {
        this.neurons[i] = this.getNeuronOutput(i);
      }
      return;
    }

    // copy inputs to optimized net
    for (let i = 0; i < this.neuronMap.length; i++) {
      const N = this.neuronMap[i];
      this.optimizedNet!.neurons[i] = this.neurons[N];
    }

    // calculate optimized net
    this.optimizedNet!.update();

    // map outputs from optimized net
    for (let i = 0; i < this.neuronMap.length; i++) {
      const N = this.neuronMap[i];
      this.neurons[N] = this.optimizedNet!.neurons[i];
    }
  }

  /**
   * setInputs() - Set all input values for the neural network
   * @param inputs
   */
  setInputs(inputs: Array<number>) {
    for (let i = 0; i < this.config.numInputs; i++) {
      this.neurons[i] = inputs[i];
    }
  }

  /**
   * setInput() - Set the input value of one input neuron
   * @param input
   * @param value
   */
  setInput(input: number, value: number) {
    if (input >= this.config.numInputs) throw { message: "invalid neuron" };
    this.neurons[input] = value;
  }

  /**
   * getInputs() - Get output values from the neural network
   * @returns
   */
  getOutputs() {
    const outputs = new Array<number>(this.config.numOutputs);
    const offset = this.config.numInputs + this.config.numHidden;
    for (let i = 0; i < this.config.numOutputs; i++) {
      outputs[i] = this.neurons[i + offset];
    }
    return outputs;
  }

  /**
   * optimize the inner structure of the network
   */
  optimize() {
    // find input neurons and hidden neurons which are not connected to any output.
    // -> find inputs and neurons, which are connected to at least one output.
    // --> NL := list of all input and hidden neurons
    //     for (iOut = 0; iOut < numOutputs.Length; iOut++) {
    //        H := follow the weights to find all hidden neurons connected to output[iOut]
    //        for (iHidden = 0; iHidden < H.Length; iHidden++) {
    //          I := follow the weights to find all input neurons connected to H[iHidden]
    //        }
    //     }

    console.log("optimize net");
    let reqInputs: Array<number> = [];
    let reqHidden: Array<number> = [];
    let reqOutputs: Array<number> = [];

    for (let iOut = 0; iOut < this.config.numOutputs; iOut++) {
      const thisOut = this.config.numInputs + this.config.numHidden + iOut;
      const thisIn = this.findInputNeurons(thisOut);
      const inputNeurons = thisIn.filter((v) => this.isInput(v));
      const outputNeurons = thisIn.filter((v) => this.isOutput(v));
      if (inputNeurons.length > 0 && outputNeurons.length > 0) {
        reqInputs.push(...inputNeurons);
        reqHidden.push(...thisIn.filter((v) => this.isHidden(v)));
        reqOutputs.push(...outputNeurons);
      }
    }

    reqInputs = reqInputs
      .filter((v, i, self) => self.indexOf(v) === i)
      .sort((a, b) => (a < b ? -1 : 1));
    reqHidden = reqHidden
      .filter((v, i, self) => self.indexOf(v) === i)
      .sort((a, b) => (a < b ? -1 : 1));
    reqOutputs = reqOutputs
      .filter((v, i, self) => self.indexOf(v) === i)
      .sort((a, b) => (a < b ? -1 : 1));

    let requiredNeurons: Array<number> = [];
    requiredNeurons.push(...reqInputs, ...reqHidden, ...reqOutputs);
    requiredNeurons = requiredNeurons
      .filter((v, i, self) => self.indexOf(v) === i)
      .sort((a, b) => (a < b ? -1 : 1));

    this.neuronMap = requiredNeurons;

    this.isOptimized = true;
    this.optimizedNet = new NeuralNet({
      numInputs: reqInputs.length,
      numHidden: reqHidden.length,
      numOutputs: reqOutputs.length,
    });
    const L = this.neuronMap.length;
    for (let i = 0; i < L; i++) {
      for (let j = 0; j < L; j++) {
        const mappedI = this.neuronMap[i];
        const mappedJ = this.neuronMap[j];
        const weightIJ = this.getWeight(mappedI, mappedJ);
        this.optimizedNet.setWeight(i, j, weightIJ);
      }
    }
  }

  /**
   * Find indices of input neurons for specified neuron
   * @param neuron
   * @returns
   */
  findInputNeurons(neuron: number): Array<number> {
    let C: Array<number> = [];
    const V: Array<number> = [];

    C.push(neuron);
    while (C.length > 0) {
      const nextC = C[0];
      C.splice(0, 1);

      if (!V.includes(nextC)) {
        V.push(nextC);

        for (let i = 0; i < this.numNeurons; i++) {
          const thisWeight = this.getWeight(i, nextC);
          if (thisWeight != 0) {
            C.push(i);
          }
        }
        C = C.filter((v, i, self) => self.indexOf(v) === i);
      }
    }
    return V;

    //for (let i = 0; i < this.numNeurons; i++) {
    //  const thisWeight = this.getWeight(i, neuron);
    //  if (thisWeight != 0) {
    //    result.push(i);
    //  }
    //}
    //return result;
  }

  isInput(index: number): boolean {
    return index < this.config.numInputs;
  }

  isHidden(index: number): boolean {
    const t = index - this.config.numInputs;
    return t >= 0 && t < this.config.numHidden;
  }

  isOutput(index: number): boolean {
    const t = index - this.config.numHidden - this.config.numInputs;
    return t >= 0 && t < this.config.numOutputs;
  }

  getInputIndex(inputIndex: number): number {
    return inputIndex;
  }

  getHiddenIndex(hiddenIndex: number): number {
    return hiddenIndex + this.config.numInputs;
  }

  getOutputIndex(outputIndex: number): number {
    return outputIndex + this.config.numHidden + this.config.numInputs;
  }

  isMapped(index: number): boolean {
    return this.neuronMap.includes(index);
  }
}
