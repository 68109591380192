import { Organism, OrganismFactoryFunc, OrganismOptions } from "./organism";

/**
 * Location - Describe a location in the world
 */
export interface Location {
  x: number;
  y: number;
}

/**
 * Region - Describe a region in the world
 */
export class Region {
  x: Readonly<number>;
  y: Readonly<number>;
  width: Readonly<number>;
  height: Readonly<number>;

  constructor(x: number, y: number, width: number, height: number) {
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
  }

  contains(x: number, y: number) {
    const left = this.x;
    const right = this.x + this.width;
    const top = this.y;
    const bottom = this.y + this.height;
    return x >= left && x < right && y >= top && y < bottom;
  }
}

/**
 * WorldOptions - Parameters for the world
 */
export interface WorldOptions {
  gridSize: number;
}

/**
 * World - The world is formed by a 2x2 grid of cells.
 * Each of these cells can be populated by one Organism.
 */
export class World {
  static EMPTY_CELL: number = -1;

  // world options
  config: Readonly<WorldOptions>;

  // Grid size of this world.
  get gridSize() {
    return this.config.gridSize;
  }

  // List of organisms
  organisms: Array<Organism>;

  // World cells. Use `getCell(x,y)` and `setCell(x,y,organism)` to manipulate data.
  cellData: Array<number>;

  // List of regions where organisms become survivors at end of a generation
  survivalRegions: Array<Region>;

  /**
   * Construct a new empty world.
   * @param gridSize
   */
  constructor(options: WorldOptions) {
    this.config = options;
    this.organisms = Array<Organism>();
    this.cellData = Array<number>(this.gridSize * this.gridSize).fill(
      World.EMPTY_CELL
    );
    this.survivalRegions = Array<Region>();
    this.survivalRegions.push(...this.createDefaultSurvivorRegions(options));
  }

  /**
   * Aux: create survivor regions in the corners of the world and one in the center
   * @param options
   * @returns
   */
  createDefaultSurvivorRegions(options: WorldOptions): Array<Region> {
    const largeBoxSize = 30;
    const smallBoxSize = 15;
    return [
      new Region(
        (options.gridSize - largeBoxSize) / 2,
        (options.gridSize - largeBoxSize) / 2,
        largeBoxSize,
        largeBoxSize
      ),
      new Region(0, 0, smallBoxSize, smallBoxSize),
      new Region(
        options.gridSize - smallBoxSize,
        0,
        smallBoxSize,
        smallBoxSize
      ),
      new Region(
        0,
        options.gridSize - smallBoxSize,
        smallBoxSize,
        smallBoxSize
      ),
      new Region(
        options.gridSize - smallBoxSize,
        options.gridSize - smallBoxSize,
        smallBoxSize,
        smallBoxSize
      ),
    ];
  }

  /**
   * Populate this world with organisms.
   * @param parents Parents of the new generation. If no parents are given, random organisms will spawn.
   * @param numOrganisms Number of organisms to create.
   */
  populate(
    numOrganisms: number,
    brainSize: number,
    genomeSize: number,
    spawnFunction: OrganismFactoryFunc,
    parents?: Array<Organism>
  ) {
    const uint32Max = 4294967295;

    // empty world
    this.organisms.splice(0, this.organisms.length);
    this.cellData.fill(World.EMPTY_CELL);

    // build a list of locations
    const L = new Array<Location>();
    for (let x = 0; x < this.gridSize; x++) {
      for (let y = 0; y < this.gridSize; y++) {
        L.push({ x, y });
      }
    }

    // spawn organisms
    for (let i = 0; i < numOrganisms; i++) {
      // pick random location
      const nextLAt = Math.round(Math.random() * uint32Max) % L.length;
      const nextL = L[nextLAt];
      L.splice(nextLAt, 1);
      // spawn organism
      const newOrganism = spawnFunction(nextL, brainSize, genomeSize, parents);
      // place organism in world
      this.organisms.push(newOrganism);
      this.setCell(nextL.x, nextL.y, this.organisms.length - 1);
    }
  }

  /**
   * Populate the world with a given population
   * @param organisms
   */
  populateWith(organisms: Array<Readonly<OrganismOptions>>) {
    this.organisms = organisms.map((o) => new Organism(o as OrganismOptions));
    this.cellData.fill(World.EMPTY_CELL);
    this.organisms.forEach((o, i) =>
      this.setCell(o.location.x, o.location.y, i)
    );
  }

  /**
   * Reset organisms to their starting positions
   */
  reset() {
    this.cellData.fill(World.EMPTY_CELL);
    this.organisms.forEach((o, i) =>
      this.setCell(o.config.location.x, o.config.location.y, i)
    );
  }

  /**
   * setCell() - Place organism at index in cell[x,y]
   * @param x x-coordinate of the cell
   * @param y y-coordinate of the cell
   * @param organism index of organism, or -1 if cell is empty
   */
  setCell(x: number, y: number, organism: number) {
    this.cellData[y * this.gridSize + x] = organism;
    if (organism != -1) {
      this.organisms[organism].location = { x, y };
    }
  }

  /**
   * getCell() - Return the index of the organism living in cell[x,y]
   * @param x x-coordinate of the cell
   * @param y y-coordinate of the cell
   * @returns index of organism, or -1 if cell is empty
   */
  getCell(x: number, y: number): number {
    return this.cellData[y * this.gridSize + x];
  }
}
