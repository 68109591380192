import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c095cd68"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["viewBox"]
const _hoisted_2 = ["x", "y"]
const _hoisted_3 = ["x", "y", "width", "height"]
const _hoisted_4 = ["cx", "cy", "fill", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    class: "worldview",
    viewBox: _ctx._viewBox
  }, [
    (_ctx._focusOrganism)
      ? (_openBlock(), _createElementBlock("rect", {
          key: 0,
          x: _ctx._toWorld(_ctx._focusOrganism.location.x) - 15,
          y: _ctx._toWorld(_ctx._focusOrganism.location.y) - 15,
          class: "focus",
          width: "30",
          height: "30"
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx._survivalRegions, (region, index) => {
      return (_openBlock(), _createElementBlock("rect", {
        key: index,
        x: _ctx._toWorld(region.x) - 4,
        y: _ctx._toWorld(region.y) - 4,
        class: "survivalRegion",
        width: _ctx._toWorldScale(region.width),
        height: _ctx._toWorldScale(region.height)
      }, null, 8, _hoisted_3))
    }), 128)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx._organisms(), (organism, index) => {
      return (_openBlock(), _createElementBlock("circle", {
        key: index,
        cx: _ctx._toWorld(organism.location.x),
        cy: _ctx._toWorld(organism.location.y),
        r: "4",
        class: "organism",
        fill: _ctx._colorByGenome(organism),
        onClick: ($event: any) => (_ctx.onSelectOrganism(organism))
      }, null, 8, _hoisted_4))
    }), 128))
  ], 8, _hoisted_1))
}