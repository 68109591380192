
import { OrganismGenome } from "@/model/genome";
import { Organism } from "@/model/organism";
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    organism: { type: Object },
  },
})
export default class GenomeView extends Vue {
  organism!: Organism;

  get _genome(): OrganismGenome {
    return this.organism.config.genome;
  }
}
